@import './mixins.scss';
@import './variables.scss';

/* fallback */
@font-face {
  font-style: normal;
  font-weight: 300;
  font-display: block;
  font-family: 'Material Symbols Outlined';
  src:
    local('Material Icons Outlined'),
    local('MaterialIconsOutlined'),
    url('./assets/material-icons/MaterialSymbolsOutlined.ttf')
      format('truetype');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  direction: ltr;
  font-weight: 300;
  word-wrap: normal;
  white-space: nowrap;
  text-transform: none;
  display: inline-block;
  letter-spacing: normal;
  font-size: $base-icon-size;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@include breakpoint(mobile) {
  .material-symbols-outlined {
    font-size: $base-icon-size;
  }
}
