@mixin breakpoint($point) {
  @if $point == mobile {
    @media (max-width: 599.98px) and (orientation: portrait),
      (max-width: 959.98px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),
      (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {
      @content;
    }
  } @else if $point == desktop {
    @media (min-width: 840px) and (orientation: portrait),
      (min-width: 1280px) and (orientation: landscape) {
      @content;
    }
  }
}
