// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import './variables.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$quala-portal-primary: mat.define-palette(mat.$blue-palette);
$quala-portal-accent: mat.define-palette(mat.$blue-palette, 700, 800, 900);

// The warn palette is optional (defaults to red).
$quala-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$quala-portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $quala-portal-primary,
      accent: $quala-portal-accent,
      warn: $quala-portal-warn,
    ),
    typography: mat.define-typography-config(
        $font-family: $font-family,
      ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($quala-portal-theme);

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-state-layer-size: 24px;
  --mat-radio-checked-ripple-color: #{$secondary-color};
  --mdc-radio-selected-hover-icon-color: #{$secondary-color};
  --mdc-radio-selected-focus-icon-color: #{$secondary-color};
  --mdc-radio-unselected-hover-icon-color: #{$secondary-color};
  --mdc-radio-selected-icon-color: #{$primary-color};
  --mdc-radio-unselected-icon-color: #{$primary-color};
  --mdc-form-field-label-text-color: #{$secondary-icon};
  --mdc-radio-unselected-pressed-icon-color: #{$primary-color};
}

.mdc-form-field > label {
  color: $darker-text;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #{$tertiary-icon};
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0px !important;
}

.mdc-radio .mat-mdc-radio-touch-target {
  height: 26px;
  width: 26px;
}
