// Brand Identity Colors
$primary-color: #05347a;
$secondary-color: #008751;
$primary-lighter-color: #2596be;
$secondary-lighter-color: #8cc63e;

// Buttons Colors
$primary-button-color: #008571;
$secondary-button-color: #8cc63e;
$primary-button-text: #ffffff;
$secondary-button-text: #53565a;
$tertiary-button-text: #000000;
$primary-button-hovered-background: #ffffff;
$primary-button-hovered-text: #008571;
// Icons Colors
$primary-icon: #ffffff;
$secondary-icon: #05347a;
$tertiary-icon: #008571;
$menu-icon: #d4d4d4;
$primary-border: #d4d4d4;

// Texts Colors
$primary-text: #000000;
$secondary-text: #ffff;
$darker-text: #53565a;
$disabled-text: #b0b0b0;
$menu-darker-text: #53565a;
$menu-lighter-text: #d4d4d4;
$primary-institutional-text: #05347a;
$secondary-institutional-text: #8cc63e;
$primary-institutional-subtitle: #000000;

$warn-text: #d65600;
$error-text: #d60600;
$warn: #d65600;
$error: #d60600;
$background: #ffffff;
$detailed-background: #e6e6e6;
$disabled-color: #f0f0f0;

// Borders Color
$primary-border: #d4d4d4;
$secondary-border: #ffffff;

// Listing component
$list-component-header-background: #cecece25;
$primary-list-component-border: #cecece;
$list-component-hover: #05347a34;
$list-component-warn-row: #ff00002b;
$primary-list-component-icon: #cecece;
$secondary-list-component-icon: #434040;

// Actions bar component colors
$secondary-actions-bar-text: #53565a;
$secondary-actions-bar-icon: #008751;

// Date Picker Colors
$secondary-date-picker-background: #ffffff71;
$primary-date-picker-text: #fff;

// Menu scrollbar
// Subnav
$secondary-scroll: #d4d4d4;

// Font Family
$font-family: 'Roboto', sans-serif;

// Font Sizes
$desktop-title-size: 2.5rem;
$desktop-subtitle-size: 1.5rem;

$mobile-title-size: 2rem;
$mobile-subtitle-size: 1rem;
$mobile-label-size: 0.875rem;

$input-size: 1rem;
$select-size: 0.75rem;
$base-text-size: 1rem;
$large-text-size: 1.125rem;
$small-text-size: 0.625rem;
$label-text-size: 0.75rem;
$detail-text-size: 0.875rem;
$base-dynamic-table-text-size: 0.875rem;

$small-icon-size: 1rem;
$base-icon-size: 1.5rem;
$large-icon-size: 2.5rem;

// Gap's
$base-gap-size: 0.5rem; // 8px
$medium-gap-size: 0.75rem; // 12px
$large-gap-size: 1rem; // 16px
$huge-gap-size: 1.5rem; // 24px

// Font weights
$h2: 600;
$regular: 400;

:root {
  --primary-color: #05347a;
  --darker-icon-color: #53565a;
  --light-icon-color: #ffffff;
}

$brand: (
  'blue': #05347a,
  'dark-grey': #53565a,
  'black': #000000,
  'white': #ffffff,
  'green': #008751,
  'light-green': #8cc63e,
  'light-blue': #1b85ea,
  'light-grey': #7e8283,
  'light-grey-2': #e6e6e6,
  'light-grey-3': #d4d4d4,
  'light-grey-7': #a1a1a1,
  'light-grey-9': #7d7d7d,
);

// Application Colors
$jungle-green: #006937;
$deep-green: #004725;
$forest-green: #26a532;
$light-green: #0047256e;
$very-light-pink: #cdcdcd;
$brown-grey: #929292;
$deep-orange: #d65600;
$red: #d60600;
$blue: #3f71d3;
$charcoal: #464646;
$charcoal-light: #969696;
$white: #fff;

// Card
$default-slot-width: 192px;
$panel-mode-small-column-width: 160px;
$panel-mode-medium-column-width: 192px;
$panel-mode-large-column-width: 236px;
$panel-mode-extra-large-column-width: 270px;
$panel-mode-2-extra-large-column-width: 300px;
$panel-mode-3-extra-large-column-width: 320px;

@function brand-color($color) {
  @return map-get($brand, $color);
}
