@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
@import './variables.scss';
$theme-color: $primary-color;

.owl-dt-container,
.owl-dt-container * {
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-size: 1rem;
  background: #ffffff;
  pointer-events: auto;
  z-index: 1000;
}

.owl-dt-container-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }
}

.owl-dt-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: flex;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
  color: #000000;

  .owl-dt-calendar-control-content {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .owl-dt-calendar-control-button {
      padding: 0 0.8em;

      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.owl-dt-calendar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: flex;
  align-items: center;

  .owl-dt-calendar-table {
    width: calc(100% - 3em);

    .owl-dt-calendar-header th {
      padding-bottom: 0.25em;
    }
  }
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  .owl-dt-calendar-header {
    color: rgba(0, 0, 0, 0.4);

    .owl-dt-weekdays {
      th {
        font-size: 0.7em;
        font-weight: 400;
        text-align: center;
        padding-bottom: 1em;
      }
    }

    .owl-dt-calendar-table-divider {
      position: relative;
      height: 1px;
      padding-bottom: 0.5em;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: -0.5em;
        right: -0.5em;
        height: 1px;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    font-size: 0.8em;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 999px;
    color: inherit;
  }

  .owl-dt-calendar-cell-out {
    opacity: 0.2;
  }

  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    border-color: rgba(0, 0, 0, 0.4);
  }

  .owl-dt-calendar-cell-selected {
    color: hsla(0, 0%, 100%, 0.85);
    background-color: $theme-color;

    &.owl-dt-calendar-cell-today {
      box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.85);
    }
  }

  .owl-dt-calendar-cell-disabled {
    cursor: default;

    & > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
      color: rgba(0, 0, 0, 0.4);
    }

    & > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
      opacity: 0.4;
    }

    & > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  .owl-dt-calendar-cell-active:focus
    > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
  :not(.owl-dt-calendar-cell-disabled):hover
    > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .owl-dt-calendar-cell-in-range {
    background: rgba($theme-color, 0.2);

    &.owl-dt-calendar-cell-range-from {
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
    }

    &.owl-dt-calendar-cell-range-to {
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
    }
  }
}

.owl-dt-timer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: 0.5em;
  outline: none;
}

.owl-dt-timer-box {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}

.owl-dt-timer-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.2em 0;

  .owl-dt-timer-input {
    display: block;
    width: 2em;
    text-align: center;
    color: white;
    border: 1px solid $theme-color;
    background-color: $theme-color;
    border-radius: 3px;
    outline: medium none;
    font-size: 1.2em;
    padding: 0.2em;
  }
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  height: 100%;
  left: -0.3em;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%);
    background-color: currentColor;
  }

  &:before {
    top: 35%;
  }

  &:after {
    bottom: 35%;
  }
}

.owl-dt-control-button {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  color: inherit;

  .owl-dt-control-button-content {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  &:focus > .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &:not(:-moz-focusring):focus > .owl-dt-control-button-content {
    box-shadow: none;
  }
}

.owl-dt-control-period-button {
  .owl-dt-control-button-content {
    height: 1.5em;
    padding: 0 0.5em;
    border-radius: 3px;
    transition: background-color 100ms linear;
  }

  &:hover > .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .owl-dt-control-button-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
    margin: 0.1em;
    transition: transform 200ms ease;
  }
}

.owl-dt-control-arrow-button {
  .owl-dt-control-button-content {
    padding: 0;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.4);
    cursor: default;
  }

  svg {
    width: 50%;
    height: 50%;
    fill: currentColor;
  }
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  max-width: 17.5em;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);

  .owl-dt-calendar,
  .owl-dt-timer {
    width: 100%;
    color: $theme-color;
  }

  .owl-dt-calendar {
    height: 20.25em;
  }
}

.owl-dt-inline-container {
  display: inline-block;
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;

  .owl-dt-calendar {
    min-width: 250px;
    min-height: 330px;
    max-width: 750px;
    max-height: 750px;
  }

  .owl-dt-timer {
    min-width: 250px;
    max-width: 750px;
  }
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 58vh;
      height: 62vh;
    }

    .owl-dt-timer {
      width: 58vh;
    }
  }
}

@media all and (orientation: portrait) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 80vw;
      height: 80vw;
    }

    .owl-dt-timer {
      width: 80vw;
    }
  }
}

.owl-dt-container-buttons {
  display: flex;
  width: 100%;
  height: 2em;
  color: $theme-color;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  border-radius: 0;

  .owl-dt-control-button-content {
    height: 100%;
    width: 100%;
    transition: background-color 100ms linear;
  }

  &:hover {
    .owl-dt-control-button-content {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .owl-dt-container-range {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    font-size: 0.8em;

    &:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .owl-dt-container-info-active {
    color: $theme-color;
  }
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $theme-color;

  .owl-dt-timer-hour12-box {
    border: 1px solid currentColor;
    border-radius: 2px;
    transition: background 200ms ease;

    .owl-dt-control-button-content {
      width: 100%;
      height: 100%;
      padding: 0.5em;
    }

    &:focus,
    &:hover {
      .owl-dt-control-button-content {
        background: $theme-color;
        color: #ffffff;
      }
    }
  }
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
